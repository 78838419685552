/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { contact_links, media, social } from './footer-arrays';
import './footer.scss';

export const Footer = () => {
  const [expand, setExpand] = React.useState('');
  const isMobile = navigator.appVersion.includes('Mobile');
  const history = useNavigate();

  return (
    <div className={'footer_container '}>
      <div className='footer-content' >
        <div className="footer-bottom">
          <div>
            <img
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              style={{ cursor: 'pointer' }}
              src="coditas_logo.png"
              alt="logo"
            />
            <div className="code-is">Code is art, Code is us</div>
          </div>
          <div className={'copyright'}>
            <span>&#169;</span>{new Date().getFullYear()} Coditas Technologies Pvt. Ltd. All rights
            reserved.
          </div>
        </div>
        <div className={'data_container'}>
          <div className={'links'}>
            <FooterTitle
              label="Pages"
              isMobile={isMobile}
              setExpand={setExpand}
              expand={expand}
            />
            <ul
              className={`${'mobile-view'}  ${expand === 'Pages' && 'expanded'
                }`}
            >
              {/* fetching links from array using map */}
              {contact_links.map((data, i) => (
                <a
                  key={i}
                  onClick={
                    data.onClick
                      ? () => data.onClick(data.active_link, history)
                      : null
                  }
                  {...(data.link === 'About Us'
                    ? {}
                    : { target: '_blank', rel: 'noreferrer' })}
                  style={{ cursor: 'pointer' }}
                  href={!data.onClick ? data.active_link : null}
                >
                  <li key={i}>{data.link}</li>
                </a>
              ))}
            </ul>
          </div>
          <div className={'links'}>
            <FooterTitle
              label="In Media"
              isMobile={isMobile}
              setExpand={setExpand}
              expand={expand}
            />
            <ul
              className={`${'mobile-view'}  ${expand === 'In Media' && 'expanded'
                }`}
            >
              {/* fetching links from array using map */}
              {media.map((data, i) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  href={data.active_link}
                >
                  <li key={i}>{data.link}</li>
                </a>
              ))}
            </ul>
          </div>
          <div className={'links'}>
            <FooterTitle
              label="Social Media"
              isMobile={isMobile}
              setExpand={setExpand}
              expand={expand}
            />
            <ul
              className={`${'mobile-view'}  ${expand === 'Social Media' && 'expanded'
                }`}
            >
              {/* fetching links from array using map */}
              {social.map((data, i) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  href={data.active_link}
                >
                  <li key={i}>{data.link}</li>
                </a>
              ))}
            </ul>
          </div>
          <hr className='footer-hr' />
        </div>
      </div>
    </div>
  );
};

const FooterTitle = (props) => {
  return (
    <div className="footer-title mon-700">
      {props.label}
      <div
        onClick={() =>
          props.expand === props.label
            ? props.setExpand('')
            : props.setExpand(props.label)
        }
        className="expander show-mobile"
      >
        {props.expand === props.label ? '-' : '+'}
      </div>
    </div>
  );
};
