// import $ from "jquery";
import India from "./assets/ic_india.svg";
import Dubai from "./assets/ic_dubai.svg";
import USA from "./assets/ic_usa.svg";

const handleLocation = (id, his) => {
  if (window.location.pathname !== `/${id}`) {
    his(id);
  }
  // var offset = $("#" + id).offset();
  // $("html, body").animate(
  //   {
  //     scrollTop: offset?.top,
  //   },
  //   1500
  // );
};

export const office_address = [
  {
    title: "USA",
    addressLine1: `16192 Coastal Hwy `,
    addressLine2: ` Lewes,`,
    addressLine3: ` Delaware 19958,`,
    addressLine4: ` USA`,
    icon: USA,
  },
  {
    title: "Dubai",
    addressLine1: `301 3rd floor Aspin`,
    addressLine2: ` Commercial Tower,`,
    addressLine3: ` KKR business center`,
    addressLine4: ` Dubai, UAE PO Box 33879`,
    icon: Dubai,
  },
  {
    title: "India",
    addressLine1: `301 Gaia Apex,`,
    addressLine2: ` Viman Nagar,`,
    addressLine3: ` Pune, Maharashtra`,
    addressLine4: ` 411014`,
    icon: India,
  },
];

export const contact_links = [
  {
    link: "Privacy Policy",
    active_link: "/privacy-policy",
  },
  {
    link: "About Us",
    onClick: handleLocation,
    active_link: "/about-us",
  },
  {
    link: "Contact Us",
    active_link: "/contactus",
    onClick: handleLocation,
  },
];

export const media = [
  {
    link: "Your Story",
    active_link:
      "https://yourstory.com/2020/01/startup-iit-bombay-alumni-bootstrapped-coding/amp",
  },
  {
    link: "Economic Times",
    active_link:
      "https://hr.economictimes.indiatimes.com/news/trends/employee-experience/the-people-first-culture-why-it-matters/78778556",
  },
  {
    link: "Express Computers",
    active_link:
      "https://www.expresscomputer.in/cloud/clean-coders-why-india-isnt-on-the-list/63760/",
  },
  {
    link: "CIOL",
    active_link:
      "https://www.ciol.com/startup-circle-success-story-coditas-connectandsell-case-study/",
  },
];
export const social = [
  {
    link: "LinkedIn",
    active_link: "https://www.linkedin.com/company/coditas/",
  },
  {
    link: "Instagram",
    active_link: "https://www.instagram.com/coditas_hq/",
  },
  {
    link: "Facebook",
    active_link: "https://www.facebook.com/Coditas/",
  },
  {
    link: "Twitter",
    active_link: "https://twitter.com/coditashq",
  },
];
export const res = [
  {
    link: "Company Brochure",
    active_link: "/portfolio",
  },
];
