import React, { useEffect } from "react";
import "./navbar.scss";

function NavBar(props) {
  const pathname = window.location.pathname;
  const hash = window.location.hash;
  const [showMenu, setShowMenu] = React.useState(false);
  const [changeColor, setChangeColor] = React.useState(false);

  const backgroundChange = () => {
    const offset = window.scrollY;
    if (props.offset) {
      offset > props.offset ? setChangeColor(true) : setChangeColor(false);
      return;
    }
    if (props.bgColor === "transparent") {
      offset > 25 ? setChangeColor(true) : setChangeColor(false);
    } else {
      offset > 550 ? setChangeColor(true) : setChangeColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", backgroundChange);
    // const paths = ["/services", "/portfolio", "#/#services", "#/#portfolio"];
    // if (paths.includes(pathname) || paths.includes(hash)) {
    //   let id = pathname.split("/")[1] || hash.split("#/#")[1];
    //   const doc = document.getElementById(id);
    //   doc?.scrollIntoView({ behavior: "smooth" });
    // }
  });

  return (
    <div
      className={`navbar-wrapper
       ${changeColor || showMenu ? "change-color" : ""}
        ${
          props.bgColor
            ? props.bgColor === "transparent" &&
              (!changeColor ? "bgTrans" : "bgBlue")
            : ""
        }`}
    >
      <div className="navbar_container">
        <div
          onClick={() => {
            if (window.location.hostname.includes("healthcare")) {
              window.location.href = "https://coditas.com";
            }
            props.history("/");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          className="coditas_logo_container"
        >
          <img
            className={"coditas_logo"}
            src={changeColor ? "coditas_blue.png" : "coditas_white.png"}
            alt="img"
          />
        </div>
        <div className={`navbar_links_container ${showMenu ? "show" : "hide"}`}>
          <ul>
            <li
              onClick={() => {
                setShowMenu(false);
                if (window.location.hostname.includes("healthcare")) {
                  window.location.href = "https://coditas.com";
                }
                props.history("/");
              }}
              className={`nav-item ${changeColor && "show-b"} ${
                pathname === "/" &&
                window.location.hostname === "coditas.com" &&
                hash === "" &&
                "active"
              }`}
            >
              Home
            </li>
            <li
              onClick={() => {
                setShowMenu(false);
                props.history("/about-us");
              }}
              className={`nav-item ${changeColor && "show-b"} ${
                pathname === "/about-us" && "active"
              }`}
            >
              About Us
            </li>
            <a href="#services">
              <li
                onClick={() => {
                  props.history("/#services");
                  setShowMenu(false);
                }}
                className={`nav-item ${changeColor && "show-b"} ${
                  window.location.hash === "#services" && "active"
                }`}
              >
                Services
              </li>
            </a>
            <a href="#portfolio">
              <li
                onClick={() => {
                  props.history("/#portfolio");
                  setShowMenu(false);
                }}
                className={`nav-item ${changeColor && "show-b"} ${
                  window.location.hash === "#portfolio" && "active"
                }`}
              >
                Portfolio
              </li>
            </a>
            {/* <li
              onClick={() => {
                setShowMenu(false);
                props.history('/our-work');
              }}
              className="nav-item"
            >
              Our Work
            </li> */}
            <li
              onClick={() =>
                (window.location.href = " https://blog.coditas.com/")
              }
              className={`nav-item ${changeColor && "show-b"}`}
            >
              Blog
            </li>
            <li
              onClick={() =>
                (window.location.href = "https://careers.coditas.com/")
              }
              className={`nav-item ${changeColor && "show-b"}`}
            >
              Careers
            </li>
            <li
              className={`nav-item ${changeColor && "show-b"} ${
                window.location.hash === "#contactus" && "active"
              }`}
              onClick={() => {
                setShowMenu(false);
                props.history("/contactus");
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
        <div
          className={`menuColor ${showMenu ? "open" : ""}`}
          id="menu-toggle"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="rect first"></div>
          <div className="rect hide"></div>
          <div className="rect second"></div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
