import React from 'react';
import { NavEvent } from '../EventNavbar/reusableprops';
import logo from './coditas_logo.png';
import './eventNav.scss';
import NavBar from '../Navbar/navbar';
import { useNavigate } from 'react-router-dom';

export const EventNav = ({ title = '', subtitle = '', isForm = false, buttonProps = {}, invertColor }) => {
  const [isBottom, setBottom] = React.useState(false);

  React.useEffect(() => {
    window.onscroll = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 50) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    };
  });
  const history = useNavigate();

  return (
    <div className={'block'}>
      <NavBar history={history} active="contactus" logo={logo} invertColor={invertColor} />
      <div className="newcontainer">
        <div className={`arrow-event ${isBottom ? 'nav-top' : 'nav-bottom'}`}>
          <div>
            <NavEvent navEvent={title} subtitle={subtitle} />
          </div>
          <div>
            {subtitle && <div><h2 className='subtitle'>UX & quality-focused digital <br /><div className="second-line">engineering company</div></h2></div>}
            {title === "Our Work" && <h2 className='subtitle our-work-subtitle'>Through Clean Code and <br /><div className="second-line">Intuitive User Experience</div></h2>}
          </div>
        </div>
      </div>
    </div>
  );
};
