import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import "./common.scss";

import { EventNav } from "./components/EventNav";
import { Footer } from "./components/Footer";

const AboutUs = React.lazy(async () => await import("./components/AboutUs"));
const PrivacyPolicy = React.lazy(
  async () => await import("./components/PrivacyPolicy/PrivacyPolicy")
);
const ContactUsForm = React.lazy(
  async () => await import("./components/ContactUs")
);
const Hiring = React.lazy(async () => await import("./components/Hiring"));
const Banner = React.lazy(
  async () => await import("./components/Banner/banner")
);
const Technologies = React.lazy(
  async () => await import("./components/Technologies")
);
const OurWork = React.lazy(async () => await import("./components/OurWork"));
const OurServices = React.lazy(
  async () => await import("./components/OurServices")
);
const OurOffices = React.lazy(
  async () => await import("./components/OurOffices")
);
const AdCampaignContactUsForm = React.lazy(
  async () => await import("./components/AdCampaignContactUs")
);
const CookiePolicy = React.lazy(
  async () => await import("./components/Cookie/CookiePolicy")
);
const MidContent = React.lazy(
  async () => await import("./components/MidContent")
);
const Services = React.lazy(async () => await import("./components/Services"));

function App() {
  return (
    <div className="App ">
      <ScrollUpButton />
      <BrowserRouter>
        <Suspense fallback={<FallBack />}>
          <Routes>
            <Route exact path="/contactus" element={<ContactUsForm />} />
            <Route exact path="/contact-us" element={<ContactUsForm />} />
            <Route exact path="/about-us" element={<AboutUsPage />} />
            <Route
              exact
              path="/design-ux-with-us"
              element={AdCampaignContactUsForm}
            />
            {/* <Route exact path="/our-work" Component={OurWorkPage} /> */}
            <Route exact path="/cookie-policy" element={<CookiePolicy />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/:redirectTo" element={<Home />} />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/healthcare" element={<Services />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

const FallBack = () => {
  return (
    <div className="load-center">
      <img
        src="https://events-cover.s3.ap-south-1.amazonaws.com/coditas_loading.gif"
        alt="loading"
      />
    </div>
  );
};

const AboutUsPage = () => {
  React.useEffect(() => window.scrollTo(0, 0));
  return (
    <React.Fragment>
      <div className="eventnav" style={{ position: "static" }}>
        <EventNav title="About Us" subtitle={true} />
      </div>
      <AboutUs />
      <Footer />
    </React.Fragment>
  );
};

// const OurWorkPage = () => {
//   React.useEffect(() => window.scrollTo(0, 0));

//   return (
//     <React.Fragment>
//       <div className="eventnav" style={{ position: 'static' }}>
//         <EventNav title="Our Work" subtitle={false} />
//       </div>
//       <OurWorkPageComponent />
//       <Footer />
//     </React.Fragment>
//   );
// };

const Home = () => {
  React.useEffect(() => window.scrollTo(0, 0));
  return (
    <React.Fragment>
      <Banner />
      <MidContent />
      <OurServices />
      <OurWork />
      <Technologies />
      <Hiring />
      <OurOffices />
      <Footer />
    </React.Fragment>
  );
};
export default App;
